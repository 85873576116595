<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Demo</a></li>
                <li class="breadcrumb-item text-capitalize active" aria-current="page">{{state}} demos</li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage {{state}} demos</h4>
          </div>
        </div>
      </div>
      <div class="col-sm-0 tx-right col-lg-7" v-if="state=='pending'">
        <button type="button" class="btn btn-primary mg-t-8 mg-r-0 mg-b-0" @click="$store.dispatch('modalOpen','createDemo')">New Demo</button>
      </div><!-- col -->
    </div><!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-9 d-flex mr-0 pr-0">
          <select v-model="filter.size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <ul class="ml-2">
            <li><a href="javascript:;" @click="prevDay">
              <i class="fas fa-arrow-left"></i>
            </a></li>
            <li><a href="javascript:;" @click="toDay">
              Today
            </a></li>
            <li><a href="javascript:;" @click="nextDay">
              <i class="fas fa-arrow-right"></i>
            </a></li>
          </ul>
          <ul class="ml-2">
            <li><a href="javascript:;" @click="prevMonth">
              <i class="fas fa-arrow-left"></i>
            </a></li>
            <li><a href="javascript:;" @click="thisMonth">
              Month
            </a></li>
            <li><a href="javascript:;" @click="nextMonth">
              <i class="fas fa-arrow-right"></i>
            </a></li>
          </ul>
          <div class="ml-2">
            <label class="filter-lavel mb-0 mr-1">From</label>
            <input type="date" v-model="filter.from" placeholder="From" class="filter-input">
            <label class="filter-lavel mb-0 ml-1">To</label>
            <input type="date" v-model="filter.to" placeholder="To" class="ml-1 filter-input">
          </div>
        </div>
        <div class="col-md-3 ml-0 pl-0">
          <input type="text" v-model="filter.key"  class="float-right" placeholder="Search...">
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
          <tr>
            <th scope="col" class="text-center">S.N</th>
            <th scope="col">Company</th>
            <th scope="col">Address</th>
            <th scope="col">Contact</th>
            <th scope="col">Email</th>
            <th scope="col" class="text-center">Action</th>
          </tr>
          </thead>
          <tbody v-for="(demo,index) in dataSets" :key="index">
          <tr v-if="!removeList.includes(demo.id)">
            <th scope="row" class="text-center">{{++index}}</th>
            <td>{{demo.company}}</td>
            <td>{{demo.address}}</td>
            <td>{{demo.contact}}</td>
            <td>{{demo.email}}</td>
            <td class="text-center">
              <a
                      href="javascript:;"
                      @click="details('demoDetails',demo.id)"
                      title="More about demo details" class="mr-3"
              >
                <i class="fa fa-eye"></i>
              </a>
              <a href="javascript:;" @click="drop(demo.id)">
                <i class="fa fa-trash"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <Pagination />
      </div>
    </div>
    <Create />
    <Edit />
    <Details />
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import Details from "./details";
  import Create from "./create";
  import Edit from "./edit";
  import Pagination from "../components/pagination/pagination";
  import {Search} from "../../../mixins/search";

  export default {
    mixins: [Search],
    components: { Create, Details, Edit, Pagination },
    data() {
      return {
        state: "",
      };
    },
    methods: {
      details(modal, id) {
        this.$store.commit("setDataId", id);
        this.$store.dispatch("modalOpen", modal);
      },
      getData() {
        this.$store.dispatch("clearRemoveList");
        this.state = this.$route.params.state;
        this.$store.commit("getData", `api/demos/${this.$route.params.state}/size/${this.filter.size}`);
      },
      getPlan() {
        this.$store.commit("getData1", `api/plans`);
      },
      drop(id) {
        this.$store.commit("setDataId", id);
        this.$store.commit("dropRecord", `api/demo/${id}`);
      },filterByTwoDates(){
        this.$store.commit("setApiUrl", "api/demos/filter/date");
        this.$store.commit("sendData", {'state':this.$route.params.state,'from':this.filter.from,'to':this.filter.to,'size':this.filter.size});
      },filterByDay(){
        this.$store.commit("setApiUrl", "api/demos/filter/day");
        this.$store.commit("sendData", {'state':this.$route.params.state,'size':this.filter.size,'day':this.filter.full_day});
      },filterByMonth(){
        this.$store.commit("setApiUrl", "api/demos/filter/month");
        this.$store.commit("sendData", {'state':this.$route.params.state,'size':this.filter.size,'month':this.filter.full_month});
      }
    },
    computed: {
      ...mapGetters([
        "icons",
        "today",
        "dataId",
        "dataLists",
        "dataLists1",
        "removeList",
        "pagination",
        "eventMessage",
      ]),
    },
    mounted() {
      this.getData();
      this.getPlan();
    },
    watch: {
      "$route.params.state"() {
        this.getData();
      },
      eventMessage(value) {
        if (value.indexOf("deleted success") >= 0) {
          this.$store.commit("setRemoveList", this.dataId);
        }
      },
    },destroyed() {
      this.$store.dispatch("destroyEvent");
    }
  };
</script>