<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId=='editDemo'"
      :class="modalState?'show':''"
      tabindex="-1"
      role="dialog"
      style="display: block;"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal_ac_head" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Demo</h5>
            <a class="close" @click="$store.dispatch('modalClose')">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="updateDemo"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Company Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            <label>Full Name</label>
                          </div>
                          <div class="col-md-7" :class="{ invalid: $v.formData.name.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Your Name"
                              @blur="$v.formData.name.$touch()"
                              v-model.trim="formData.name"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.name.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.name.required"
                            >Name field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.name.minLength"
                            >At least 2 character name.</p>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            <label>Company Name</label>
                          </div>
                          <div class="col-md-7" :class="{ invalid: $v.formData.company.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Company Name"
                              @blur="$v.formData.company.$touch()"
                              v-model.trim="formData.company"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.company.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.company.required"
                            >Company Name field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.company.minLength"
                            >At least 2 character company name.</p>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            <label>PAN / VAT Number</label>
                          </div>
                          <div class="col-md-7" :class="{ invalid: $v.formData.pan.$error }">
                            <input
                              type="number"
                              class="form-control border-radius-0"
                              placeholder="PAN / VAT Number"
                              @blur="$v.formData.pan.$touch()"
                              v-model.trim="formData.pan"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.pan.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.pan.required"
                            >PAN/Registration field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.pan.minLength"
                            >9 character PAN/VAT number.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.pan.maxLength"
                            >9 character PAN/VAT number.</p>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            <label>Company Address</label>
                          </div>
                          <div class="col-md-7" :class="{ invalid: $v.formData.address.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Company Address"
                              @blur="$v.formData.address.$touch()"
                              v-model.trim="formData.address"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.address.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.address.required"
                            >Address field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.address.minLength"
                            >At least 2 character address.</p>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            <label>Company Email</label>
                          </div>
                          <div class="col-md-7" :class="{ invalid: $v.formData.email.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Company Email"
                              @blur="$v.formData.email.$touch()"
                              v-model.trim="formData.email"
                              readonly
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.email.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.email.required"
                            >Email field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.email.minLength"
                            >At least 6 character email.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.email.email"
                            >Please enter valid email.</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">
                            <label>Contact Number</label>
                          </div>
                          <div class="col-md-7" :class="{ invalid: $v.formData.contact.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              placeholder="Contact Number"
                              @blur="$v.formData.contact.$touch()"
                              v-model.trim="formData.contact"
                              required
                            />
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.contact.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.required"
                            >Contact field is required.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.minLength"
                            >At least 7 character contact number.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.contact.numeric"
                            >Please enter numeric value.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">General Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            <label>Plan</label>
                          </div>
                          <div class="col-md-7" :class="{ invalid: $v.formData.plan_id.$error }">
                            <select
                              class="form-control border-radius-0"
                              @blur="$v.formData.plan_id.$touch()"
                              v-model.trim="formData.plan_id"
                              required
                            >
                              <option value>Select Your Plan</option>
                              <option
                                v-for="(plan,key) in dataLists1"
                                :value="plan.id"
                                :key="key"
                              >{{plan.title}}</option>
                            </select>
                          </div>
                          <div class="col-md-12 text-right" v-if="$v.formData.plan_id.$error">
                            <p
                              class="error_level"
                              v-if="!$v.formData.plan_id.required"
                            >Please choose your plan.</p>
                            <p
                              class="error_level"
                              v-if="!$v.formData.plan_id.numeric"
                            >Invalid plan id.</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">
                            <label>Workspace</label>
                          </div>
                          <div class="col-md-7" :class="{ invalid: $v.formData.workspace.$error }">
                            <input
                              type="text"
                              class="form-control border-radius-0"
                              @change="$v.formData.workspace.$touch()"
                              v-model.trim="formData.workspace"
                              readonly
                            />
                          </div>
                          <div class="col-md-12" v-if="eventMessage">
                            <p class="error_level text-right">{{eventMessage}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary">Submit</button>
                <p
                  :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                >{{eventMessage}}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  computed: {
    ...mapGetters([
      "apiRoot",
      "token",
      "processing",
      "fileLocation",
      "eventMessage",
      "dataId",
      "dataLists",
      "dataLists1",
      "modalId",
      "modalState",
    ]),
  },
  mounted() {
    this.$store.commit("getData1", "api/plans");
  },
  data() {
    return {
      details: [],
      formData: {
        name: "",
        company: "",
        address: "",
        email: "",
        contact: "",
        pan: "",
        plan_id: "",
        workspace: "",
      },
    };
  },
  validations: {
    formData: {
      name: { required, minLength: minLength(2), maxLength: maxLength(255) },
      company: { required, minLength: minLength(2), maxLength: maxLength(255) },
      address: { required, minLength: minLength(2), maxLength: maxLength(255) },
      email: { required, email, minLength: minLength(6) },
      contact: { required, numeric, minLength: minLength(7) },
      pan: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
      plan_id: { required, numeric },
      workspace: { required, minLength: minLength(4) },
    },
  },
  methods: {
    checkWorkspace() {
      if (this.formData.workspace) {
        this.$store.commit(
          "getData2",
          `api/workspace/${this.formData.workspace}`
        );
      }
    },
    updateDemo() {
      if (!this.$v.formData.$invalid) {
        this.$store.commit("setApiUrl", `api/demo/${this.dataId}`);
        this.$store.commit("updateData", this.formData);
      } else {
        this.$store.commit("setEventMessage", `Please fill required fields.`);
      }
    },
    updateUser() {
      axios
        .post(`${this.apiRoot}api/create/database/user`, {
          workspace: this.formData.workspace,
        })
        .then((res) => {
          this.$store.dispatch("enableMessage");
          this.$store.commit("setEventMessage", res.data.message);
        })
        .catch((error) => {
          this.$store.commit("manageErrors", error);
        });
    },
  },
  watch: {
    details(value) {
      if (value) {
        this.formData.name = value.name;
        this.formData.company = value.company;
        this.formData.address = value.address;
        this.formData.email = value.email;
        this.formData.contact = value.contact;
        this.formData.pan = value.pan;
        this.formData.plan_id = value.plan_id;
        this.formData.workspace = value.workspace;
      }
    },
    dataId(value) {
      this.details = this.dataLists.find(function (data) {
        return data.id == value;
      });
    },
    "formData.workspace"(value) {
      if (value) {
        this.formData.workspace = value
          .toLowerCase()
          .trim()
          .split(".")
          .join("")
          .replace(/[\s\/]/g, "");
      } else {
        this.error = "";
      }
    },
    eventMessage(value) {
      if (value.indexOf("updated success") >= 0) {
        this.updateUser();
      }
    },
  },
};
</script>